import { ComponentMenuMenuItem } from '@/gql/generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import React from 'react'

type Props = {
  item: Maybe<ComponentMenuMenuItem>
}

const CustomLinkItem = ({ item }: Props) => {
  return (
    <li className="has-children static">
      <a
        href={item?.href ?? '#'}
        className="font-albert font-semibold text-base leading-5 text-black no-underline relative transition-all hover:text-link-hover"
      >
        {item?.title}
      </a>
      {item?.links && !!item?.links?.length && (
        <ul
          className="sub-menu invisible absolute mt-5 flex w-[220px] flex-wrap gap-y-6 rounded-b-md bg-white p-4 opacity-0 shadow-[0_3px_6px_0px_rgba(0,0,0,0.16)] 
										transition-all duration-200"
        >
          {item.links.map((itemLink, index) => (
            <li key={index}>
              <a
                href={itemLink?.href ?? '#'}
                className="font-albert font-normal text-base text-black no-underline hover:text-link-hover"
              >
                {itemLink?.title}
              </a>
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}

export default CustomLinkItem