import { AuthorEntity, BlogCategoryEntity, BlogPostEntity } from '@/gql/generated/graphql'
import { useRouter } from 'next/navigation'
import React from 'react'

type Props = {
  item: BlogCategoryEntity | BlogPostEntity | AuthorEntity | undefined
  onClose: () => void
}

const ResultItem = (props: Props) => {
  if (props.item == undefined) return null

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter()

  const visitItem = () => {
    router.push(`/${props.item?.attributes?.slug}`)
    props.onClose()
  }
  return (
    <div onClick={visitItem} className='w-full p-4 rounded-md border border-[#EBEBEB] shadow-sm flex items-center justify-between hover:border-gray-300 cursor-pointer'>
      <div className='flex flex-col gap-y-1'>
        {(()=> {
          switch (props.item.__typename) {
            case "AuthorEntity":
              return (
                <p>{props.item.attributes?.firstName ?? ''} {props.item.attributes?.lastName ?? ''}</p>
              )
            case "BlogCategoryEntity":
            case "BlogPostEntity":
              return (
                <p>{props.item.attributes?.title}</p>
              )
          }
        })()}
      </div>
      <button onClick={visitItem} className='font-Roboto font-medium text-sm text-black underline hover:no-underline'>
        View
      </button>
    </div>
  )

}

export default ResultItem