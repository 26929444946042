'use client'
import { Enum_Componentmenumenuitem_Itemtype, MenuEntity } from '@/gql/generated/graphql'
import { useState } from 'react'
import CustomLinkItem from './Menu/CustomLinkItem'
import BlogCategoryItem from './Menu/BlogCategoryItem'

type Props = {
  menuData: MenuEntity
  isOpen: boolean
  handleToggleNav: () => void
}

const MobileMenu = ({ menuData, handleToggleNav, isOpen }: Props) => {
  
  return (
    <div className={`responsive-menu ${isOpen ? 'open' : ''} md:hidden`}>
      <nav className={`${isOpen ? 'open' : ''} main-menu`}>
        <div onClick={handleToggleNav} className="close-nav-menu w-10 h-10 bg-white m-2.5 justify-end items-center cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>            
        </div>        
        <ul>
          {menuData.attributes?.items?.map((item, index) => {
            if (item?.itemType === Enum_Componentmenumenuitem_Itemtype.CustomLink) {
              return <CustomLinkItem key={index} item={item} />
            } else {
              return <BlogCategoryItem key={index} item={item} includeSubCategories={item?.includeSubCategories} />
            }
          })}
        </ul>
      </nav>
      <div onClick={handleToggleNav} className={`menu-overlay ${isOpen ? 'active': ''} fixed z-50 bg-black/50 inset-0 invisible opacity-0 transition-all duration-300`}></div>
    </div>
  )
}

export default MobileMenu