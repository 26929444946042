import { ComponentMenuMenuItem } from '@/gql/generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import Link from 'next/link'
import React from 'react'

type Props = {
  item: Maybe<ComponentMenuMenuItem>
  includeSubCategories?: Maybe<boolean> | undefined
}

const BlogCategoryItem = ({ item, includeSubCategories }: Props) => {
  const blog_category_data = item?.blog_category?.data?.attributes
  return (
    <li className="has-children static sumon">
      <Link
        href={`/${blog_category_data?.slug ?? '#'}`}
        className="font-medium text-xl block py-[29px] px-5 text-link-color"
      >
        {blog_category_data?.title}
      </Link>
      {!!includeSubCategories ? (
        <>
          {!!blog_category_data?.sub_categories?.data.length ? (
            <ul
              className="sub-menu invisible absolute mt-5 flex w-[220px] flex-wrap gap-y-6 rounded-b-md bg-white p-4 opacity-0 shadow-[0_3px_6px_0px_rgba(0,0,0,0.16)] 
                        transition-all duration-200"
            >
              {blog_category_data?.sub_categories?.data.map((subCat, index) => (
                <li key={index}>
                  <Link
                    href={`/${blog_category_data.slug}/${subCat?.attributes?.slug ?? '#'}`}
                    className="font-albert font-normal text-base text-black no-underline hover:text-link-hover"
                  >
                    {subCat?.attributes?.title}
                  </Link>
                </li>
              ))}
            </ul>
          ) : null}
        </>
      ): null}
    </li>
  )
}

export default BlogCategoryItem