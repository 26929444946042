'use client'
import useDebounce from '@/lib/useDebounce'
import React, { FC, Fragment, useEffect, useState } from 'react'
import { AuthorEntity, BlogCategoryEntity, BlogPostEntity, UploadFileEntityResponse } from '@/gql/generated/graphql'
import { AnimatePresence, Transition, motion } from 'framer-motion'
import ResponsiveImage from '@/components/core/ResponsiveImage'
import LoadingSpinner from './LoadingSpinner'
import ResultItem from './ResultItem'
import { searchKeyword } from '@/actions/serverActions'

type Props = {
  isOpen: boolean
  onClose: () => void
  logo: UploadFileEntityResponse | undefined
}

const Results: FC<Props> = ({ isOpen, onClose, logo }) => {
  const [keyword, setKeyword] = useState('')
  const [results, setResults] = useState<(AuthorEntity | BlogCategoryEntity | BlogPostEntity | undefined )[]>([])
  const [loading, setLoading] = useState(false)
  const debouncedKeyword = useDebounce(keyword, 500)

  useEffect(() => {
    if (debouncedKeyword.length < 1) {
      setLoading(false)
      setResults([])
    }
    if (debouncedKeyword.length > 0) {
      setLoading(true)

      const fetchData = async (debouncedKeyword: string) => {
        let results = await searchKeyword(debouncedKeyword)
        return results as (AuthorEntity | BlogCategoryEntity | BlogPostEntity | undefined)[]
      }

      fetchData(debouncedKeyword).then((data) => {
        setResults(data)
        setLoading(false)
      })
    }
    return () => {}
  }, [debouncedKeyword])

  return (
    <>
    <AnimatePresence>
        {isOpen && (
          <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 10, opacity: 0 }} className='fixed inset-0 w-screen min-h-screen overflow-y-auto bg-white z-50'>
            <div className='container mt-10'>
              <ResponsiveImage className='' image={logo} />
              <div className='mt-4 w-full flex items-center relative'>
                <input onChange={(e) => setKeyword(e.target.value)} type='text' className='mt-4 h-10 bg-transparent border-b-2 border-gray-600 focus:border-gray-800 w-full focus:outline-none' />
                {loading ? (
                  <LoadingSpinner className='w-7 h-7 absolute right-0 cursor-pointer' />
                ) : (
                  <Fragment>
                    <svg
                      onClick={() => {
                        setKeyword('')
                        setResults([])
                        onClose()
                      }}
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-7 h-7 absolute right-0 cursor-pointer'
                    >
                      <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                    </svg>
                  </Fragment>
                )}
              </div>
              <div className='mt-4 mb-8 flex flex-col gap-y-2'>
                {loading ? (
                  <Fragment>
                    <div className='h-16 w-full rounded-md border border-gray-300 shadow-sm animate-pulse block'></div>
                    <div className='h-16 w-full rounded-md border border-gray-300 shadow-sm animate-pulse block'></div>
                    <div className='h-16 w-full rounded-md border border-gray-300 shadow-sm animate-pulse block'></div>
                  </Fragment>
                ) : (
                  <ul className='flex flex-col gap-y-2 overflow-y-auto'>
                    {results.map((result, index) => (
                      <ResultItem onClose={onClose} key={index} item={result} />
                    ))}
                  </ul>
                )}
                {!loading && debouncedKeyword.length > 0 && results.length < 1 && <p>No relevant search result found</p>}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default Results
