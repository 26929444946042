'use client'
import React, { useState } from 'react'
import { EzFormData } from '@/customTypes'
import { submitEzForm } from '@/actions/serverActions'
import { useForm } from 'react-hook-form'
import { AnimatePresence, motion } from 'framer-motion'

const SubscriptionForm = () => {
  const { register, handleSubmit, reset } = useForm()
  const [inProgress, setInProgress] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const submitMessage = async (data: any) => {
    const { email} = data

    if (!email) return

    setInProgress(true)

    const newContactData: EzFormData = {
      formName: "Subscription Form",
      formData: {
        email
      }
    }

    let response = await submitEzForm(newContactData)
    if(response){
      reset()
      setInProgress(false)
      setIsSuccess(true)
      setTimeout(() => {
        setIsSuccess(false)
      }, 5000)
    }

  }

  return (
    <div className="footer-newsletter w-full sm:w-1/2 lg:w-1/4 mb-10 lg:mb-0">
      <AnimatePresence>
        {isSuccess && (
          <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 10, opacity: 0 }} className='success-message mb-6'>
            <div className='rounded-b border-t-4 border-teal-500 bg-teal-100 px-4 py-3 text-teal-900 shadow-md' role='alert'>
              <div className='flex'>
                <div className='py-1'>
                  <svg className='mr-4 h-6 w-6 fill-current text-teal-500' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                    <path d='M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z'></path>
                  </svg>
                </div>
                <div>
                  <p className='font-bold'>Thank you for your subscription.</p>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <h3 className="font-bold text-white text-base uppercase mb-5">JOIN OUR NEWSLETTER</h3>
      <form className='newsletter-form' onSubmit={handleSubmit(submitMessage)}>
        <input className='email' type="email" placeholder="Enter your email" aria-describedby="emailHelp" required {...register('email')} />
          {!!inProgress ? (
          <button className='btn-light' type="button"disabled>Please Wait...</button>
          ) : (
          <button className='btn-light' type="submit">Subscribe</button>
        )
        }
      </form>
    </div>
  )
}

export default SubscriptionForm