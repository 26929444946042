'use client'
import React, { Fragment, useState } from 'react'
import MainMenu from './MainMenu'
import { Maybe, MenuEntityResponse } from '@/gql/generated/graphql'
import ResponsiveImage from '@/components/core/ResponsiveImage'
import Link from 'next/link'
import SocialLinks from '@/components/core/SocialLinks'
import MobileMenu from './MobileMenu'
import Results from './Search/Results'

type Props = {
  menu: Maybe<MenuEntityResponse> | undefined
}

const Header = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const handleToggleNav = () => {
    setIsOpen(!isOpen)
  }

  const menuData = props.menu?.data
  if (menuData == undefined) return null
  return (
    <Fragment>
      <header className="header py-5 md:py-0 relative z-10 shadow-[0_3px_6px_0_rgba(0,0,0,0.07)]">
        <div className="container">
            <div className="flex flex-wrap flex-col sm:flex-row justify-between items-center">
              <div className="logo-box lg:w-[300px] mb-10 sm:mb-0">
                <Link href="/">
                  <ResponsiveImage image={menuData.attributes?.logo} className='h-auto' />
                </Link> 
              </div>
              <MainMenu menu={menuData}/>
              <div className="header-right inline-flex items-center justify-center md:justify-end w-full sm:w-auto lg:w-[300px]">
                  {menuData.attributes?.social_link_section && 
                    <SocialLinks socialLinksSection={menuData.attributes?.social_link_section} classNames='social-link'/>
                  }
                  <div className="search-form">
                    <form>
                      <button className="search-btn" type='button' onClick={() => setIsSearchOpen(true)} >search</button>
                    </form>
                  </div>                    
                  <div onClick={handleToggleNav} className={`open-nav-menu block md:hidden ml-5`}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                      </svg>                      
                  </div>
              </div>
            </div>
        </div>        
      </header>
      <Results onClose={() => setIsSearchOpen(false)} isOpen={isSearchOpen} logo={menuData.attributes?.logo ?? undefined} />
      <MobileMenu menuData={menuData} isOpen={isOpen} handleToggleNav={handleToggleNav}/>
    </Fragment>
  )
}

export default Header