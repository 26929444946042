import { Enum_Componentmenumenuitem_Itemtype, MenuEntity } from "@/gql/generated/graphql";
import React from "react";
import CustomLinkItem from "./Menu/CustomLinkItem";
import BlogCategoryItem from "./Menu/BlogCategoryItem";

type Props = {
	menu: MenuEntity
};

const MainMenu = (props: Props) => {
	return (
		<nav className="main-menu">
			{props.menu && 
				<ul className="md:ml-auto md:mr-auto hidden md:flex flex-wrap items-center justify-center list-none space-x-1">
					{props.menu.attributes?.items?.map((item, index) => {
						if(item?.itemType === Enum_Componentmenumenuitem_Itemtype.CustomLink){
							return <CustomLinkItem key={index} item={item}/>
						}else{
							return <BlogCategoryItem key={index} item={item} includeSubCategories={item?.includeSubCategories} />
						}
					})}
				</ul>
			}
		</nav>
	);
};

export default MainMenu;
