'use client';

import React from 'react'
import { useServerInsertedHTML } from 'next/navigation';
import { Albert_Sans, Josefin_Sans } from "next/font/google";

const albert = Albert_Sans({
	subsets: ['latin'],
	variable: '--font-family-albert',
	fallback: ["system-ui", "Helvetica Neue", "Helvetica", "Arial"],
	weight: ["400", "700"],
	display: "swap",
});

const josefin = Josefin_Sans({
	subsets: ['latin'],
	variable: '--font-family-josefin',
	fallback: ["system-ui", "Helvetica Neue", "Helvetica", "Arial"],
	weight: ["400", "500", "600", "700"],
	display: "swap",
});

const Fonts = () => {
	useServerInsertedHTML(() => {
		return (
			<style
				dangerouslySetInnerHTML={{
					__html: `
          :root {
            --font-family-albert: ${albert.style.fontFamily};
						--font-family-josefin: ${josefin.style.fontFamily};
          }
        `,
				}}
			/>
		);
	});

	return null;
}

export default Fonts