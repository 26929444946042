import { NEXT_PUBLIC_GTM } from "@/env";

type WindowWithDataLayer = Window & {
	dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export const GTM_ID = NEXT_PUBLIC_GTM;

export const pageview = (url: string) => {
	if (typeof window.dataLayer !== "undefined") {
		window.dataLayer.push({
			event: "pageview",
			page: url,
		});
	} else {
		// console.log({
		// 	event: "pageview",
		// 	page: url,
		// });
	}
};
