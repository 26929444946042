import { Maybe, SocialLinkSectionEntityResponse } from '@/gql/generated/graphql'
import Link from 'next/link'
import React from 'react'

type Props = {
  socialLinksSection: Maybe<SocialLinkSectionEntityResponse>
  classNames?: string
}

const SocialLinks = (props: Props) => {
  return (
    <ul
      className='social-list flex flex-wrap space-x-4 lg:space-x-6 mr-10 xl:mr-[70px]'
    >
      {props.socialLinksSection?.data?.attributes?.socialItem?.links?.map((link, index) => (
        <li key={index} className={link?.type}>
          <Link href={link?.href ?? "#"}>{link?.type}</Link>
        </li>
      ))}
    </ul>
  )
}

export default SocialLinks